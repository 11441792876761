const Home = () => {
  return (
    <h5
      style={{
        display: "table-cell",
        height: "100vh",
        verticalAlign: "middle",
        textAlign: "center",
        width: '100vw'
      }}
    >
      Некорректная ссылка на подборку
    </h5>
  );
};

export default Home;
