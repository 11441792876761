import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Action, Fab } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";

const Employee = ({ employee, whatsAppLink }) => {
  const fabSettings = {
    position: {
      bottom: 0,
      right: 0,
    },
    alwaysShowTitle: true,
    mainButtonStyles: {
      backgroundColor: "#3498db",
      fontSize: "1.5rem",
    },
  };

  const handleWhatsAppClick = () => {
    var win = window.open(whatsAppLink, "_blank");
    win.focus();
  };
  return (
    <div style={{ backgroundColor: "#262626", borderRadius: "0.5rem" }}>
      <Container  style={{ padding: "1rem" }}>
        <Row>
          <Col>
            <Image
              src={employee.imageUrl}
              roundedCircle
              style={{ width: "100%", maxWidth: '200px' }}
            />
          </Col>
          <Col>
            <h4>{employee.fullName}</h4>
            {!!employee.phone1 && (
              <div>
                <a href={`tel:${employee.phone1}`}>{employee.phone1}</a>
              </div>
            )}
            {!!employee.phone2 && (
              <div>
                <a href={`tel:${employee.phone2}`}>{employee.phone2}</a>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <Fab
        mainButtonStyles={fabSettings.mainButtonStyles}
        style={fabSettings.position}
        icon={"☎"}
        alwaysShowTitle={fabSettings.alwaysShowTitle}
      >
        <Link to={`tel:${employee.phone1}`}>
          <Action
            style={{ backgroundColor: "#3498db", width: 140, borderRadius: 48 }}
          >
            Позвонить
          </Action>
        </Link>
        <Action
          style={{ backgroundColor: "green", width: 140, borderRadius: 48 }}
          onClick={handleWhatsAppClick}
        >
          WhatsApp
        </Action>
      </Fab>
    </div>
  );
};

export default Employee;
