import { useContext, useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { RealEstateService } from "../../../services/realestate.service";
import Item from "./Item";
import SpinnerPage from "../../ui/SpinnerPage";
import { NavbarContext } from "../../../providers/NavbarProvider";
import LoadingItem from "./LoadingItem";
import { useQuery } from "@tanstack/react-query";
import Home from "../Home";

const List = () => {
  const { domain, ids } = useParams();

  const [searchParams] = useSearchParams();
  const e = searchParams.get("e");

  // const [realestates, setRealestates] = useState([]);

  const { setNavbarTitle } = useContext(NavbarContext);

  // useEffect(() => {
  //   if (!ids || !domain) return;
  //   const fetchRealEstates = async () => {
  //     const response = await RealEstateService.getList(domain, ids, e);
  //     console.log(response.data);
  //     setRealestates(response.data.data);
  //     setNavbarTitle(response.data.agency);
  //   };
  //   fetchRealEstates();
  // }, [domain, ids, e]);

  const { data, status, error } = useQuery({
    queryKey: ["list", domain, ids, e],
    queryFn: () => RealEstateService.getList(domain, ids, e),
  });

  useEffect(() => {
    setNavbarTitle(data?.data?.agency);
  }, [data?.data?.agency]);

  if (status === "loading") {
    return (
      <Stack gap={2}>
        {(() => {
          let content = [];
          for (let i = 0; i < ids?.split(",").length; i++) {
            content.push(<LoadingItem key={i} />);
          }
          return content;
        })()}
      </Stack>
    );
  }
  if (status === "error")
    return <Home />;
  
  // setRealestates(data.data.data);
  const realestates = data.data.data;
  

  return (
    <div className="d-flex justify-content-around">
      <Stack gap={2}>
        {realestates.map((re) => {
          return <Item key={re.gid} realestate={re} domain={domain} e={e} />;
        })}
      </Stack>
    </div>
  );
};

export default List;
