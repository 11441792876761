import axios from "axios";

// const serverAddress = process.env.REACT_APP_API_URL;
const serverAddress = window.api_url;
// const serverAddress = 'https://testobj.realcrm.kz';

export const RealEstateService = {
  
    async getList(domain, ids, e = null) {
      const response = await axios.get(`${serverAddress}/api/List/${domain}/${ids}/${e ?? ''}`);
                
      return response;
    },
    async getById(domain, id, e = null) {
        const response = await axios.get(`${serverAddress}/api/RealEstate/${domain}/${id}/${e ?? ''}`);
                
        return response;
    }
};
