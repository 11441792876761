import React, { useEffect, useState } from "react";
import { Carousel, Image as BootImage, Modal } from "react-bootstrap";
import CarouselImageItem from "./CarouselImageItem";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ImageCarousel2 = ({ images }) => {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const [readyImages, setReadyImages] = useState([images[0]]);
  const imageLoaded = () => {
    if (readyImages.length < images.length) {
      setReadyImages([...readyImages, images[readyImages.length]]);
    }
  };

  useEffect(() => {
    if (images?.length > 0) {
      // const img = new Image();
      // img.src = images[0]?.miniImageUrl;
      // img.onload = () => {
      //   setReadyImages([...readyImages, img]);
      // };
      //preloading first mini image, this time gallery is hidden
      // const img = new Image();
      // img.src = images[0]?.miniImageUrl;
      // img.onload = () => {
      //   // when it finishes loading, update the component state
      //   setIsMainImageReady(true);
      // };
      // images.forEach((image) => {
      //   // console.log(image.miniImageUrl);
      //   const img = new Image();
      //   img.src = image.imageUrl;
      // });
    }
  }, []);

  if (!images?.length) return null;

  return (
    // !!isMainImageReady &&
    <>
      <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
        {images.map((image, index) => {
          return (
            <Carousel.Item key={index}>
              <CarouselImageItem
                image={image.miniImageUrl}
                setShow={setShow}
                // imageLoaded={isFirstTenImagesReady ? () => {} : imageLoaded}
                imageLoaded={imageLoaded}
                showPlaceholder={
                  index >= readyImages.length
                }
              />
            </Carousel.Item>
          );
        })}
      </Carousel>

      <Modal
        /*data-bs-theme="dark"*/ show={show}
        // fullscreen={"sm-down"}
        fullscreen={true}
        onHide={() => setShow(false)}
      >
        <Modal.Header
          data-bs-theme="dark"
          closeButton
          style={{ backgroundColor: "black", fontSize: "1rem" }}
        ></Modal.Header>
        <Modal.Body style={{ backgroundColor: "black" }}>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={null}
            style={{
              top: "50%",
              msTransform: "translateY(-50%)",
              transform: "translateY(-50%)",
            }}
          >
            {images.map((image, index) => {
              return (
                <Carousel.Item
                  key={index}
                  style={{ textAlign: "-webkit-center" }}
                >
                  {/* <BootImage
                      className="d-block"
                      style={{
                        height: "80vh",
                        objectFit: "contain",
                        // objectFit: "cover",
                        // width: "100%",
                      }}
                      src={image.imageUrl}
                      alt={`Image ${index}`}
                      fluid
                    /> */}

                  <LazyLoadImage
                    className="d-block img-fluid"
                    // placeholder='<div>'
                    wrapperClassName="222"
                    wrapperProps={{ qwe: "qweqwe" }}
                    placeholderSrc={image.miniImageUrl}
                    // effect="blur"
                    src={image.imageUrl} // use normal <img> attributes as props
                    style={{
                      // height: "80vh",
                      // objectFit: "contain",
                      // objectFit: "cover",
                      // width: "100%",
                      maxHeight: "80vh",
                    }}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default React.memo(ImageCarousel2);
