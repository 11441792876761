import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import RealEstate from "./pages/realestate/RealEstate";
import List from "./pages/list/List";
import MyNavbar from "./ui/MyNavbar";

const Router = () => {
  return (
    <BrowserRouter>
      <MyNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/List/:domain/:ids" element={<List />} />
        <Route path="/RE/:domain/:id" element={<RealEstate />} />
        <Route path="/RealEstate/:domain/:id" element={<RealEstate />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
