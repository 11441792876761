import { useContext } from "react";
import { Container, Navbar } from "react-bootstrap";
import { NavbarContext } from "../../providers/NavbarProvider";
import { useNavigate, useSearchParams } from "react-router-dom";

const MyNavbar = () => {
  const { navbarTitle } = useContext(NavbarContext);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const back = searchParams.get("back");

  if (!navbarTitle) return null;
  return (
    <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
      <Container
        style={{
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Navbar.Brand style={{ width: "100%", textAlign: "center" }}>
          {!!back && (
            <img
              src="/btn_back.png"
              className="btn-back"
              onClick={(e) => navigate(-1)}
            />
          )}
          {navbarTitle}
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
