import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import Router from './components/Router';
import AuthProvider from './providers/AuthProvider';


import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/global.css';
import { Col, Container, Row } from 'react-bootstrap';
import NavbarProvider from './providers/NavbarProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient({defaultOptions: {
  queries: {
    refetchOnWindowFocus: false, // default: true
  },
}});
root.render(
  <NavbarProvider>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Container data-bs-theme="dark" >
          <Row className="justify-content-md-center">
            <Col /*lg={4}*/ xs={12} md={12} xl={4} sm={12} lg={12} >
              <Router />
            </Col>
          </Row>
        </Container>      
        </QueryClientProvider>
    </AuthProvider>
  </NavbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
