import React, { useState } from "react";
import { Image, Placeholder } from "react-bootstrap";

const CarouselImageItem = ({
  image,
  setShow,
  imageLoaded,
  showPlaceholder,
}) => {
  console.log("CarouselImageItem");
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = () => {
    setIsLoading(false);
    imageLoaded();
  };

  return (
    <>
      {!showPlaceholder && (
        <Image
          // className="d-block"
          style={{
            maxHeight: "15rem",
            minHeight: "5rem",
            objectFit: "cover",
            width: "100%",
            display: !isLoading ? "block" : "none",
            
          }}
          src={image}
          alt={`Не удалось загрузить изображение`}
          fluid
          onClick={() => setShow(true)}
          onLoad={onLoad}
          onError={onLoad}
        />
      )}
      <Placeholder
        animation="glow"
        style={{ display: isLoading ? "block" : "none" }}
      >
        <Placeholder xs={12} style={{ height: "12em" }} />
      </Placeholder>
    </>
  );
};

export default React.memo(CarouselImageItem);
