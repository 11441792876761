import { Table } from "react-bootstrap";
import OneChoiceChars from "./OneChoiceChars";

const DetailsPartial = ({ realestate }) => {
  //для промбаз не ставим свойство tableLayout, оно делает колонки по 50%
  return (
    <Table
      style={
        [15, 16].includes(realestate.dealCategoryID)
          ? {}
          : { tableLayout: "fixed" }
      }
    >
      <tbody>
        {!!realestate.zhk && (
          <tr>
            <td>Жилой комплекс</td>
            <td>{realestate.zhk?.title}</td>
          </tr>
        )}
        {!!realestate.cottege && (
          <tr>
            <td>Коттеджный городок</td>
            <td>{realestate.cottege?.title}</td>
          </tr>
        )}
        {!!realestate.square && (
          <tr>
            <td>Общая площадь</td>
            <td>{realestate.square} м²</td>
          </tr>
        )}
        {!!realestate.liveSquare && (
          <tr>
            <td>Жилая площадь</td>
            <td>{realestate.liveSquare} м²</td>
          </tr>
        )}
        {!!realestate.kitchen && (
          <tr>
            <td>Площадь кухни</td>
            <td>{realestate.kitchen} м²</td>
          </tr>
        )}

        {!!realestate.floor && (
          <tr>
            <td>Этаж</td>
            <td>
              {realestate.floor}
              {!!realestate.floorFrom && ` из ${realestate.floorFrom}`}
            </td>
          </tr>
        )}
        {!!realestate.levels && (
          <tr>
            <td>Кол-во уровней</td>
            <td>{realestate.levels}</td>
          </tr>
        )}
        {!!realestate.ceilingHeight && (
          <tr>
            <td>Потолки</td>
            <td>{realestate.ceilingHeight} м</td>
          </tr>
        )}
        {!!realestate.commerceLocationType && (
          <tr>
            <td>Расположение объекта</td>
            <td>{realestate.commerceLocationType}</td>
          </tr>
        )}
        {!!realestate.businessCenter && (
          <tr>
            <td>Название объекта (БЦ, ТЦ, ЖК или рынка)</td>
            <td>{realestate.businessCenter}</td>
          </tr>
        )}
        {!!realestate.buildingYear && (
          <tr>
            <td>Год постройки</td>
            <td>{realestate.buildingYear}</td>
          </tr>
        )}
        {!!realestate.buildingType && (
          <tr>
            <td>Тип строения</td>
            <td>{realestate.buildingType.toLowerCase()}</td>
          </tr>
        )}
        {!!realestate.landSquare && (
          <tr>
            <td>Участок</td>
            <td>{realestate.landSquare} сот.</td>
          </tr>
        )}
        {!!realestate.massiv && (
          <tr>
            <td>Дачный массив</td>
            <td>{realestate.massiv}</td>
          </tr>
        )}
        {!!realestate.fence && (
          <tr>
            <td>Как огорожен участок</td>
            <td>{realestate.fence}</td>
          </tr>
        )}
        {realestate.divisibility === true && (
          <tr>
            <td>Возможен обмен</td>
            <td>да</td>
          </tr>
        )}
        {realestate.swap === true && (
          <tr>
            <td>Делимость</td>
            <td>да</td>
          </tr>
        )}

        <OneChoiceChars realestate={realestate} />
        {realestate.railwayDeadlock === true && (
          <tr>
            <td>Ж/д тупик</td>
            <td>есть</td>
          </tr>
        )}
        {realestate.substation === true && (
          <tr>
            <td>Своя подстанция (ТП)</td>
            <td>есть</td>
          </tr>
        )}
        {realestate.isPledge === true && (
          <tr>
            <td>В залоге</td>
            <td>да</td>
          </tr>
        )}
        {realestate.isPrivate === true && (
          <tr>
            <td>В прив. общежитии</td>
            <td>да</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default DetailsPartial;
