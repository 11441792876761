import { Circle, Map, YMaps } from "react-yandex-maps";

const YandexMap = ({ realestate }) => {
  return (
    <YMaps query={{ apikey: "ваш ключ" }}>
      <div style={{ width: "100%" }}>
        <Map
          defaultState={{
            center: [realestate.coordX, realestate.coordY],
            zoom: 11,
            controls: ["zoomControl", "fullscreenControl"],
          }}
          width={"100%"}
          height={"10rem"}
          modules={["control.ZoomControl", "control.FullscreenControl"]}
        >
          {/* <Placemark
            defaultGeometry={[
              realestate.coordX,
              realestate.coordY,
            ]} /*properties={{iconCaption  : 'blabla'}}*
          /> */}
          <Circle
            geometry={[[realestate.coordX, realestate.coordY], 300]}
            options={{
              draggable: false,
              fillColor: "#17a2ff77",
              // strokeColor: "#990066",
              strokeOpacity: 0.8,
              strokeWidth: 2,
            }}
          />
        </Map>
      </div>
    </YMaps>
  );
};

export default YandexMap;
