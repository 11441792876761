const OneChoiceChars = ({realestate}) => {
  // console.log(realestate.oneChoiceChars);
  return (
    <>
      {realestate.oneChoiceChars?.map((x) => (
        <tr key={x.charTitle}>
          <td>{x.charTitle.charAt(0).toUpperCase() + x.charTitle.slice(1)}</td>
          <td>{x.detailTitle}</td>
        </tr>
      ))}
    </>
  );
};

export default OneChoiceChars;
