import { Card, Placeholder } from "react-bootstrap";

const LoadingItem = () => {
  return (
    <Card>
      {/* <Card.Img
            variant="top"
            src="/house_default.jpg"
            style={{ width: "100%", maxHeight: "18em", objectFit: "contain" }}
          /> */}

      <Card.Body>
        <Placeholder animation="glow">
          <Placeholder xs={12} style={{ height: "12em" }} />
        </Placeholder>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder xs={6} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{" "}
          <Placeholder xs={6} /> <Placeholder xs={8} />
        </Placeholder>
      </Card.Body>
    </Card>
  );
};

export default LoadingItem;
