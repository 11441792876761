import { createContext, useEffect, useState } from "react";

export const NavbarContext = createContext()

const NavbarProvider = ({ children }) => {
    const [navbarTitle, setNavbarTitle] = useState(null)

    useEffect(() => {
        document.title = navbarTitle || 'Подборка недвижимости';
      }, [navbarTitle]);

    return (
        <NavbarContext.Provider value={{navbarTitle, setNavbarTitle}}>
            {children}
        </NavbarContext.Provider>
    )
}
export default NavbarProvider