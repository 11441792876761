import { useState } from "react";
import { Button, Card, ListGroup, Placeholder } from "react-bootstrap";
import { Link } from "react-router-dom";

const Item = ({ realestate, domain, e }) => {
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = () => {
    setIsLoading(false);
  }

  return (
    <Card>
      {!!realestate.image && (
        <Link
          to={`/RE/${domain}/${realestate.gid}?${!!e ? `e=${e}` : ""}&back=1`}
        >
          <Card.Img
            variant="top"
            src={realestate.image}
            onLoad={onLoad}
            style={{
              width: "100%",
              maxHeight: "18em",
              objectFit: "contain",
              display: isLoading ? "none" : "block",
            }}
          />
          <Placeholder
            animation="glow"
            style={{ display: isLoading ? "block" : "none" }}
          >
            <Placeholder xs={12} style={{ height: "18em" }} />
          </Placeholder>
        </Link>
      )}
      <Card.Body style={{ paddingBottom: "0" }}>
        <Card.Title>
          <div>{realestate.category}</div>
          {realestate.price}
        </Card.Title>
      </Card.Body>

      <ListGroup className="list-group-flush">
        <ListGroup.Item>{realestate.address}</ListGroup.Item>
      </ListGroup>

      {!!realestate.description && (
        <Card.Body>
          {/* <Card.Text>{(realestate.description.length > 100) ? realestate.description.slice(0, 100-1) + '…' : realestate.description }</Card.Text> */}
          <Card.Text className="truncate-to-2-lines">
            {realestate.description}
          </Card.Text>
        </Card.Body>
      )}
      {!!realestate.employee && (
        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            {realestate.employee.fullName}, {realestate.employee.phone1}
          </ListGroup.Item>
        </ListGroup>
      )}
      <Card.Body>
        <Link
          to={`/RE/${domain}/${realestate.gid}?${!!e ? `e=${e}` : ""}&back=1`}
        >
          <Button variant="primary">Подробнее</Button>
        </Link>
        <span style={{padding: "0 10px", float: "right"}}>Код объекта: #{realestate.gid}</span>
      </Card.Body>
    </Card>
  );
};

export default Item;
