import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { RealEstateService } from "../../../services/realestate.service";
import { NavbarContext } from "../../../providers/NavbarProvider";

import ImageCarousel from "./ImageCarousel";
import { Badge, Button } from "react-bootstrap";
import Employee from "./Employee";
import YandexMap from "./YandexMap";
import DetailsPartial from "./DetailsPartial";
import { InstagramEmbed } from "react-social-media-embed";
import Loading from "./Loading";
import { useQuery } from "@tanstack/react-query";
import Home from "../Home";
import ImageCarousel2 from "./ImageCarousel2";

const RealEstate = () => {
  const { domain, id } = useParams();

  const [searchParams] = useSearchParams();
  const e = searchParams.get("e");

  // const [realestate, setRealEstate] = useState({});
  const { setNavbarTitle } = useContext(NavbarContext);

  const [showFullDescription, setFullDescription] = useState(false);
  const [showFullDetails, setFullDetails] = useState(false);

  const showFullDescriptionHandler = () => {
    setFullDescription(!showFullDescription);
  };

  useEffect(() => {
    if (!id || !domain) return;
    // const fetchRealEstate = async () => {
    //   const response = await RealEstateService.getById(domain, id, e);

    //   setRealEstate(response.data.data);
    //   setNavbarTitle(response.data.agency);
    // };
    // fetchRealEstate();
    window.scrollTo(0, 0);
  }, [domain, id, e]);
  const { data, status, error } = useQuery({
    queryKey: ["realestate", domain, id, e],
    queryFn: () => RealEstateService.getById(domain, id, e),
  });

  useEffect(() => {
    setNavbarTitle(data?.data?.agency);
  }, [data?.data?.agency]);

  if (status === "loading") return <Loading />;
  if (status === "error") return <Home />;
  const realestate = data.data.data;

  return (
    <div style={{ marginTop: "1em" }}>
      <ImageCarousel2 images={realestate.images2} />
      <p>Код объекта - {realestate.gid}</p>
      <h5>
        <b>{realestate.price}</b>
      </h5>
      <h5>{realestate.header}</h5>

      <table>
        <tbody>
          <tr>
            <td style={{ verticalAlign: "top" }}>
              <img
                src="/location.png"
                width={"25rem"}
                style={{ /*float: "left",*/ marginTop: "5px" }}
              />
            </td>
            <td>
              <div>{realestate.districtAddress}</div>
              <div>{realestate.houseAddress}</div>
            </td>
          </tr>
        </tbody>
      </table>
      {!!realestate.coordX && !!realestate.coordY && (
        <YandexMap realestate={realestate} />
      )}
      <div
        className={`re-main-details ${!showFullDetails ? "hide-detail" : ""}`}
        style={{ marginTop: "1rem" }}
      >
        <DetailsPartial realestate={realestate} />
      </div>
      {showFullDetails && (
        <>
          {!!realestate.securityChars?.length > 0 && (
            <div style={{ display: "contents" }}>
              {realestate.securityChars.map((char) => {
                return (
                  <Badge
                    key={char}
                    pill
                    bg="warning"
                    text="dark"
                    style={{ marginRight: "5px" }}
                  >
                    {char}
                  </Badge>
                );
              })}
              <br />
            </div>
          )}

          {!!realestate.commonChars?.length > 0 && (
            <div style={{ display: "contents", marginTop: "5px" }}>
              {realestate.commonChars.map((char) => {
                return (
                  <Badge
                    key={char}
                    pill
                    bg="light"
                    text="dark"
                    style={{ marginRight: "5px" }}
                  >
                    {char}
                  </Badge>
                );
              })}
              <br />
            </div>
          )}

          {!!realestate.targetChars?.length > 0 && (
            <div style={{ display: "contents", marginTop: "5px" }}>
              {realestate.targetChars.map((char) => {
                return (
                  <Badge
                    key={char}
                    pill
                    bg="light"
                    text="dark"
                    style={{ marginRight: "5px" }}
                  >
                    {char}
                  </Badge>
                );
              })}
            </div>
          )}
        </>
      )}
      <div>
        <Button
          className="show-hide-details"
          variant="link"
          onClick={(e) => setFullDetails(!showFullDetails)}
        >
          {!showFullDetails ? "Показать все параметры" : "Скрыть параметры"}
        </Button>
      </div>
      {!!realestate.description && (
        <div style={{ marginTop: "1rem" }}>
          <h5>Описание</h5>
          <p
            id="content"
            style={{ whiteSpace: "pre-wrap" }}
            className={showFullDescription ? "" : "truncate-to-6-lines"}
          >
            {realestate.description}
          </p>

          <Button
            className="show-hide-details"
            variant="link"
            onClick={showFullDescriptionHandler}
          >
            {!showFullDescription ? "Раскрыть описание" : "Скрыть описание"}
          </Button>
        </div>
      )}

      {!!realestate.youtubeLink &&  (<div
              dangerouslySetInnerHTML={{ __html: `<a target='_blank' rel='noopener noreferrer' href="${realestate.youtubeLink}">${realestate.youtubeLink}</a>` }}
            />)}

      {(() => {
        if (!!realestate.instagramLink) {
          return (
            <InstagramEmbed
              style={{
                maxWidth: 550,
              }}
              url={realestate.instagramLink}
              width="100%"
            />
          );
        } else if (!!realestate.videoEmbeddedCode) {
          return (
            <div
              dangerouslySetInnerHTML={{ __html: realestate.videoEmbeddedCode }}
            />
          );
        }
      })()}

      {!!realestate.employee && (
        <Employee
          employee={realestate.employee}
          whatsAppLink={`https://api.whatsapp.com/send?phone=${realestate.employee.phone1.replace(
            /\D/g,
            ""
          )}&text=Добрый день. Меня интересует этот объект http://${
            window.location.hostname
          }/RE/${domain}/${realestate.gid}`}
        />
      )}
    </div>
  );
};

export default RealEstate;
